<template>
    <div class="about-section section-space--bottom--10">
        <div class="container">
            <div class="row mt-n5">
                <div class="col-sm-12 col-12 col-lg-6 mb-5">
                    <div align="center">
                        <h1 v-html="experience.title_vision">{{ experience.title_vision }}</h1>
                    </div>
                        <p>{{ experience.desc }}</p>
                </div>
                <div class="col-sm-12 col-12 col-lg-6 mb-5">
                    <div align="center">
                        <h1 v-html="experience.title_mission">{{ experience.title_mission }}</h1>
                    </div>
                        <p>{{ experience.desc2 }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['experience'],
        methods: {
            scrollToTop() {
                window.scrollTo(0, 0);
            }, 
            // goToContact() {
            //     this.$router.push('/contact');
            // }
        }
    };
</script>