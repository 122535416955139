<template>
    <div class="about-section section-space--inner--120">
        <div class="container">
            <div class="about-wrapper row">
                <div class="col-lg-6 col-12">
                        <FounderAndCEO :teamData="data.team" />
                    <!-- <div class="about-image-two">
                        <img :src="data.aboutUs.image" alt="image">
                        <span class="video-popup">
                            <silentbox-single :src="data.youtubeLink">
                                <div class="icon-play">
                                    <i class="ion-ios-play-outline"></i>
                                </div>
                            </silentbox-single>
                        </span>
                    </div> -->
                </div>
                <div class="col-lg-6 col-12 mt-5 order-3 order-lg-3">
                    <div>
                        <!-- <h3>{{ data.aboutUs.subTitle }}</h3> -->
                        <!-- <h1>{{ data.aboutUs.title }}</h1> -->
                        <span >{{ data.aboutUs.shortDesc }}</span>
                        <!-- <p>{{ data.aboutUs.desc }}</p> -->
                        <!-- <router-link @click.native="scrollToTop" to="service" class="ht-btn--default ht-btn--default--dark-hover section-space--top--20">Our Service</router-link> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import FounderAndCEO from './FounderAndCEO.vue'
    import data from '../../data/about.json'
    export default {
        components: {
            FounderAndCEO
        },
        data () {
            return {
                data
            }
        },
        methods: {
            scrollToTop() {
                window.scrollTo(0, 0);
            }, 
            // goToContact() {
            //     this.$router.push('/contact');
            // }
        }
    };
</script>